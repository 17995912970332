import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ProductPreview } from '../product-preview/product-preview';
import { CollectionForm } from '../collection-form/collection-form';
import styles from './app.scss';
import { useApi } from '../api-provider/use-api';
import { Loader } from '../loader/loader';
import { unreachable } from '~/ts-utils';
import { ErrorState } from '../error-state/error-state';
import PreviewBlocker from '~commons/components/preview-blocker/preview-blocker';
import { APP_ERROR, APP_LOADING, APP_READY } from './data-hooks';

export const App: React.FC = () => {
  const { appState, baseParams } = useApi((state) => ({
    appState: state.appState,
    baseParams: state.baseParams,
  }));
  const { isPreview, isEditor } = baseParams;
  const { isMobile } = useEnvironment();

  switch (appState.type) {
    case 'LOADING':
      return (
        <div className={styles.root} data-hook={APP_LOADING}>
          <Loader />
        </div>
      );
    case 'READY':
      const { entity } = appState;
      return (
        <div className={styles.root} data-hook={APP_READY}>
          {(isPreview || isEditor) && <PreviewBlocker isMobile={isMobile} />}
          {entity && (
            <ProductPreview
              product={{
                imageUrl: entity.imageUrl,
                name: entity.name,
              }}
            />
          )}
          <CollectionForm appState={appState} />
        </div>
      );
    case 'ERROR':
      return (
        <div className={styles.root} data-hook={APP_ERROR}>
          <ErrorState status={appState.status} />
        </div>
      );
    default:
      throw unreachable(appState);
  }
};
