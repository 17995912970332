import React from 'react';
import styles from '../../styles/collection-styles.scss';
import { APP_WRAPPER } from './data-hooks';

export const AppWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <section className={styles.collectionWidget} tabIndex={-1} data-hook={APP_WRAPPER}>
      {children}
    </section>
  );
};
