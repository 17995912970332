import React from 'react';
import classNames from 'classnames';
import { merge } from 'lodash';
import { MediaInput } from './media-input/media-input';
import { ReviewFormProps } from './types';
import { classes, st } from './review-form.st.css';
import {
  BODY_FIELD,
  EMAIL_FIELD,
  FORM_ERROR,
  FORM_ERROR_CTA,
  MEDIA_FIELD,
  NAME_FIELD,
  PENDING,
  RATING_FIELD,
  REVIEW_FORM,
  SUBMIT_CANCEL_BUTTONS,
  TITLE_FIELD,
} from './data-hooks';
import { TextField } from './text-field/text-field';
import { useReviewFormState } from './review-form-state';
import { TextAreaExtended } from '../text-area-extended/text-area-extended';
import { isOfType, unreachable } from '~/ts-utils';
import { FormErrorNotification } from './form-error-notification/form-error-notification';
import { SubmitCancel } from '../submit-cancel/submit-cancel';
import { useHighlightForm } from './use-highlight-form';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  ButtonPriority,
  ButtonSize,
  Ratings,
  RatingsMode as Mode,
  RatingsSize as Size,
  SectionNotification,
} from 'wix-ui-tpa/cssVars';
import { usePrevious } from '../../hooks/use-previous';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRight';
import ChevronLeft from 'wix-ui-icons-common/on-stage/ChevronLeft';
import forDirection from '~commons/hoc/for-direction';

import { ReviewContent } from '../../types';
import { CommonsTranslationKey } from '../../locale-types';
import { useTranslate } from '../../hooks/use-translate';

const DEFAULT_REVIEW_CONTENT: ReviewContent = {
  title: '',
  body: '',
  rating: 0,
  media: [],
};

const Chevron = forDirection(ChevronRight, ChevronLeft);

export const ReviewForm = <T,>({
  formSettings: { name, email, title, body, media, ratingLabel, publishButtonText },
  onSubmit,
  onCancel,
  onLoginClick,
  onValidationFailed,
  onMediaUpload,
  onMediaDoneUploading,
  onMediaRemoved,
  onBodyFocus,
  className,
  initialContent: initialContentProp,
  isEdit = false,
  isPending,
  id,
  dataHook,
  error,
  forceHoverState,
  forceErrorState,
}: ReviewFormProps<T>) => {
  const initialContent = merge({}, DEFAULT_REVIEW_CONTENT, initialContentProp);
  const { isMobile, isEditor } = useEnvironment();

  const formState = useReviewFormState({
    initialContent,
    isNameRequired: name.enabled,
    isEmailRequired: email.enabled,
    isTitleRequired: title.enabled && title.required,
    isBodyRequired: body.enabled && body.required,
    isMediaRequired: media.enabled && media.required,
    submissionError: error,
  });
  const fields = formState.fields;
  const prevForceErrorState = usePrevious(forceErrorState);
  React.useEffect(() => {
    if (forceErrorState) {
      formState.forceErrorState();
    } else if (prevForceErrorState) {
      formState.reset();
    }
  }, [forceErrorState]);

  const t = useTranslate<T | CommonsTranslationKey>();

  const opT = (s: T | CommonsTranslationKey | undefined, args?: any) =>
    s === undefined ? undefined : t(s, args);

  const handleSubmit = async () => {
    if (formState.isValid()) {
      onSubmit({
        content: formState.getContent(),
        reset: formState.reset,
        contact: formState.getContact(),
      });
    } else {
      const invalidFields = await formState.validate();
      onValidationFailed?.(invalidFields);
    }
  };

  const mediaCount = fields.media.value.filter(isOfType(['READY', 'PENDING'])).length;

  const formatAriaLabel = ({
    translated,
    isRequired,
  }: {
    translated: string;
    isRequired: boolean;
  }) => {
    return isRequired ? `${translated}. ${t('field-label.required')}` : translated;
  };

  const highlightIds = useHighlightForm(formState);
  const content = (
    <div className={st(classes.contentContainer, { isPending })}>
      {formState.submissionError && !isPending && (
        <div
          className={classes.formError}
          id={highlightIds.notification}
          data-hook={FORM_ERROR}
          tabIndex={-1}
        >
          {(() => {
            const { submissionError, clearSubmissionError } = formState;
            switch (submissionError.type) {
              case 'EMAIL_EXISTS_ERROR':
                return (
                  <FormErrorNotification
                    showIcon={false}
                    className={st(classes.formErrorNotification, classes.spacingNormal)}
                    text={t(submissionError.key)}
                    cta={
                      <SectionNotification.Button
                        suffixIcon={<Chevron />}
                        onClick={onLoginClick}
                        size={ButtonSize.tiny}
                        {...({ type: 'text' } as any)}
                        priority={ButtonPriority.basic}
                        data-hook={FORM_ERROR_CTA}
                      >
                        {t(submissionError.ctaKey)}
                      </SectionNotification.Button>
                    }
                  />
                );
              case 'TRANSLATION':
                return (
                  <FormErrorNotification
                    className={st(classes.formErrorNotification, classes.spacingNormal)}
                    text={t(submissionError.key, submissionError.keyValue)}
                    onClose={clearSubmissionError}
                  />
                );
              case 'CUSTOM':
                return (
                  <FormErrorNotification
                    className={st(classes.formErrorNotification, classes.spacingNormal)}
                    text={submissionError.message}
                    onClose={clearSubmissionError}
                  />
                );
              case 'MEMBER_CONTACT_EXISTS_ERROR':
                return null;
              default:
                throw unreachable(submissionError);
            }
          })()}
        </div>
      )}
      {(name.enabled || email.enabled) && (
        <div className={st(classes.contactSection, classes.spacingNormal)}>
          <TextField
            id={highlightIds.name}
            label={t('field-label.name', { required: true })}
            aria-label={formatAriaLabel({
              translated: t('field-label.name', { required: false }),
              isRequired: true,
            })}
            className={classes.contactName}
            value={fields.name.value}
            onChange={(e) => formState.onFieldChange('name', e.currentTarget.value)}
            errorMessage={opT(fields.name.error)}
            error={!!fields.name.error}
            autoComplete="name"
            autoFocus={!isEditor}
            withClearButton
            onClear={() => formState.onFieldChange('name', '')}
            data-hook={NAME_FIELD}
            disabled={isPending}
          />
          {email.enabled && (
            <TextField
              id={highlightIds.email}
              label={t('field-label.email', { required: true })}
              aria-label={formatAriaLabel({
                translated: t('field-label.email', { required: false }),
                isRequired: true,
              })}
              className={classes.contactEmail}
              value={fields.email.value}
              onChange={(e) => formState.onFieldChange('email', e.currentTarget.value)}
              errorMessage={opT(fields.email.error)}
              error={!!fields.email.error}
              autoComplete="email"
              withClearButton
              onClear={() => formState.onFieldChange('email', '')}
              data-hook={EMAIL_FIELD}
              disabled={isPending}
            />
          )}
        </div>
      )}
      <div
        className={st(classes.ratingFieldWrapper, classes.spacingNormal)}
        id={highlightIds.rating}
      >
        <Ratings
          starsAriaLabels={['1', '2', '3', '4', '5']}
          className={classes.rating}
          label={formatLabel({ translated: ratingLabel, isRequired: true })}
          aria-label={formatAriaLabel({
            translated: ratingLabel,
            isRequired: true,
          })}
          value={fields.rating.value}
          onSelect={(rating) => {
            formState.onFieldChange('rating', rating);
          }}
          mode={Mode.Input}
          size={Size.Large}
          errorMessage={opT(fields.rating.error)}
          error={!!fields.rating.error}
          data-hook={RATING_FIELD}
          disabled={isPending}
        />
      </div>
      {title.enabled ? (
        <TextField
          id={highlightIds.title}
          label={formatLabel({ translated: title.label, isRequired: title.required })}
          aria-label={formatAriaLabel({
            translated: title.label,
            isRequired: title.required,
          })}
          value={fields.title.value}
          onChange={(e) => formState.onFieldChange('title', e.currentTarget.value)}
          className={st(
            classes.reviewTitle,
            fields.title.error ? classes.spacingNormal : classes.spacingNoBottomMargin,
          )}
          maxLength={title.limit}
          showCharCount
          errorMessage={opT(fields.title.error)}
          error={!!fields.title.error}
          withClearButton
          onClear={() => formState.onFieldChange('title', '')}
          data-hook={TITLE_FIELD}
          disabled={isPending}
        />
      ) : null}
      {body.enabled ? (
        <TextAreaExtended
          id={highlightIds.body}
          label={formatLabel({ translated: body.label, isRequired: body.required })}
          ariaLabel={formatAriaLabel({
            translated: body.label,
            isRequired: body.required,
          })}
          value={fields.body.value}
          onChange={(v) => formState.onFieldChange('body', v)}
          className={st(
            classes.reviewBody,
            fields.body.error ? classes.spacingNormal : classes.spacingNoBottomMargin,
          )}
          maxLength={body.limit ?? 1000}
          showCharCount
          errorMessage={opT(fields.body.error)}
          error={!!fields.body.error}
          dataHook={BODY_FIELD}
          disabled={isPending}
          onFocus={onBodyFocus}
        />
      ) : null}
      {media.enabled
        ? (() => {
            const getLabel = (required: boolean) =>
              t(
                {
                  image: 'field-label.add-images' as const,
                  video: 'field-label.add-videos' as const,
                  all: 'field-label.add-images-videos' as const,
                }[media.allowedMedia],
                {
                  count: mediaCount,
                  limit: media.limit,
                  required,
                },
              );
            const label = getLabel(false);
            return (
              <MediaInput
                onChange={(m) => formState.onFieldChange('media', m)}
                onMediaDoneUploading={onMediaDoneUploading}
                onMediaRemoved={onMediaRemoved}
                uploadMediaFileFn={onMediaUpload}
                id={highlightIds.media}
                media={fields.media.value}
                label={getLabel(media.required)}
                aria-label={formatAriaLabel({
                  translated: label,
                  isRequired: media.required,
                })}
                className={st(classes.mediaInput, classes.spacingNormal)}
                disabled={mediaCount >= media.limit || isPending}
                maxLength={media.limit}
                errorMessage={opT(fields.media.error)}
                error={!!opT(fields.media.error)}
                allowedMedia={media.allowedMedia}
                dataHook={MEDIA_FIELD}
              />
            );
          })()
        : null}
      <div className={classes.footer}>
        <SubmitCancel
          className={classes.buttons}
          onCancel={
            onCancel &&
            (() =>
              onCancel({
                content: formState.getContent(),
                contact: formState.getContact(),
                reset: formState.reset,
              }))
          }
          isPending={isPending}
          cancelText={t('review-form.cancel')}
          submitText={
            isEdit ? t('review-form.update') : publishButtonText ?? t('review-form.publish.default')
          }
          dataHook={SUBMIT_CANCEL_BUTTONS}
        />
      </div>
    </div>
  );

  return (
    <form
      id={id}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      aria-label={isEdit ? t('review-form.aria-label-edit') : t('review-form.aria-label-create')}
      data-hook={classNames(REVIEW_FORM, dataHook, isPending && PENDING)}
      className={st(
        classes.container,
        { isMobile, forcedHover: forceHoverState },
        className,
        classes.isExtended,
      )}
    >
      {content}
    </form>
  );
};

const formatLabel = ({ translated, isRequired }: { translated: string; isRequired: boolean }) => {
  return isRequired ? `${translated}*` : translated;
};
