import React from 'react';
import { Text, Button } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '../../../hooks/use-translate';
import { classes } from './error-state.st.css';
import { AppErrorStatus } from '../../../common/store/app-state/app-state-types';
import { useApi } from '../api-provider/use-api';
import { ERROR_STATE, ERROR_STATE_CTA, ERROR_STATE_TEXT } from './data-hooks';

export const ErrorState: React.FC<{
  errorId?: string | null;
  status?: AppErrorStatus;
}> = ({ status, errorId }) => {
  const t = useTranslate();
  const { baseUrl } = useApi((state) => ({
    baseUrl: state.baseParams.baseUrl,
  }));
  return (
    <div className={classes.root} data-hook={ERROR_STATE}>
      {(() => {
        switch (status) {
          case 404:
            return (
              <>
                <Text data-hook={ERROR_STATE_TEXT}>{t('not-found-error.message')}</Text>
                <Button data-hook={ERROR_STATE_CTA} as="a" href={baseUrl}>
                  {t('not-found-error.button-label')}
                </Button>
              </>
            );
          default:
            return (
              <>
                <Text data-hook={ERROR_STATE_TEXT}>{t('error-boundary-fallback.message')}</Text>
                <Button data-hook={ERROR_STATE_CTA} onClick={() => window.location.reload()}>
                  {t('error-boundary-fallback.button-label')}
                </Button>
              </>
            );
        }
      })()}
      {errorId && <Text className={classes.error}>ErrorId: {errorId}</Text>}
    </div>
  );
};
