import React, { useEffect } from 'react';
import { ReviewForm } from '~commons/components/review-form/review-form';
import { useApi } from '../api-provider/use-api';
import { useFormSettings } from '~commons/hooks/use-form-settings';
import { ReviewContent } from '~commons/types';
import { COLLECTION_FORM } from './data-hooks';
import { buildReviewCreateError } from './error-builder';
import { AppReadyState } from '../../../common/store/app-state/app-state-types';
import { uploadMediaFile } from '~commons/upload-media';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useTranslate } from '~/components/CollectionWidget/hooks/use-translate';

export const DEFAULT_REVIEW_CONTENT: ReviewContent = {
  title: '',
  body: '',
  rating: 0,
  media: [],
};

export const CollectionForm: React.FC<{ appState: AppReadyState }> = ({ appState }) => {
  const { isEditor } = useEnvironment();
  const t = useTranslate();
  const {
    formState,
    createReview,
    requestLogin,
    instance,
    isReviewRequest,
    biFormValidationError,
    biClickLeaveReviewBtn,
    biFocusFormBody,
    biClickPublishReview,
  } = useApi((state, actions) => ({
    instance: state.baseParams.instance ?? '',
    isReviewRequest: state.baseParams.origin.type === 'REVIEW_REQUEST',
    formState: state.form,
    createReview: actions.createReview,
    requestLogin: actions.requestLoginAction,
    biFormValidationError: actions.biFormValidationError,
    biClickLeaveReviewBtn: actions.biClickLeaveReviewBtn,
    biFocusFormBody: actions.biFocusFormBody,
    biClickPublishReview: actions.biClickPublishReview,
  }));
  useEffect(() => {
    biClickLeaveReviewBtn();
  }, []);
  const { configuration, currentMember } = appState;
  const isFormPending = formState.status === 'PUBLISHING';
  const formSettings = useFormSettings({
    configuration,
    contactSettings: {
      name: { enabled: true },
      email: { enabled: !isReviewRequest && !currentMember },
    },
  });

  const initialContent = isEditor
    ? {
        ...DEFAULT_REVIEW_CONTENT,
        name: [currentMember?.contact?.firstName, currentMember?.contact?.lastName]
          .filter((n) => n)
          .join(' '),
        email: currentMember?.loginEmail ?? undefined,
        title: t('editor-field-input.placeholder-text'),
        body: t('editor-field-input.placeholder-text'),
      }
    : { ...DEFAULT_REVIEW_CONTENT, name: isReviewRequest ? '' : currentMember?.profile?.nickname ?? '' };

  const uploadMediaFileFn = React.useMemo(() => uploadMediaFile({ instance }), [instance]);

  return (
    <ReviewForm
      id="review-collection-form"
      isEdit={false}
      isPending={isFormPending}
      onSubmit={({ content, contact }) => {
        biClickPublishReview(content);
        createReview({
          content,
          contact,
        });
      }}
      formSettings={formSettings}
      initialContent={initialContent}
      onLoginClick={() => {
        requestLogin();
      }}
      onBodyFocus={() => biFocusFormBody()}
      onMediaUpload={uploadMediaFileFn}
      dataHook={COLLECTION_FORM}
      onValidationFailed={(error) => {
        biFormValidationError({ missingRequiredFields: error ?? [] });
      }}
      {...(formState.status === 'ERROR'
        ? { error: buildReviewCreateError(formState.errorType) }
        : {})}
    />
  );
};
